//产业振兴
export default {
    'cylx': {
        name: '产业类型',
        vue: r => require(['../views/cyzx/Cylx.vue'], r)
    },
    'tscy': {
        name: '特色产业',
        vue: r => require(['../views/cyzx/Tscy.vue'], r)
    },
}