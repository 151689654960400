//党务工作
export default {
  'dwgz-01': {
    name: '组织管理',
    vue: r => require(['../views/dwgz/Zzgl.vue'], r)
  },
  'dwgz-02': {
    name: '党员管理',
    vue: r => require(['../views/dwgz/Dygl.vue'], r)
  },
  'dwgz-03': {
    name: '流动党员',
    vue: r => require(['../views/dwgz/Lddy.vue'], r)
  },
  'dwgz-04': {
    name: '报到党员',
    vue: r => require(['../views/dwgz/Bddy.vue'], r)
  },
  'dwgz-05': {
    name: '三会一课',
    vue: r => require(['../views/dwgz/Shyk.vue'], r)
  },
  'dwgz-06': {
    name: '活动类型',
    vue: r => require(['../views/dwgz/Hdlx.vue'], r)
  },
  'dwgz-07': {
    name: '党建活动',
    vue: r => require(['../views/dwgz/Djhd.vue'], r)
  },
  'dwgz-08': {
    name: '会议计划',
    vue: r => require(['../views/dwgz/Hyjh.vue'], r)
  },
  'bmgf': {
    name: '编码规范',
    vue: r => require(['../views/dwgz/Bmgf.vue'], r)
  } 
}