import { defineAsyncComponent } from 'vue'

let routes = []
let getRoute = (name, d) => {
  let path = d.path || '/' + name
  if (path.indexOf('/') !== 0) path = '/' + path
  return {
    path,
    name: name,
    title: d.name,
    closable: d.closable !== false,
    props: true,
    component: defineAsyncComponent(() => new Promise((resolve) => {
      Am.jdtShow()
      setTimeout(() => {
        d.vue((retVue) => {
          resolve(retVue)
          Am.jdtClose()
        })
      }, 1)
    })),
    icon: d.icon
  }
}

let addRoute = (ds) => {
  for (let key in ds) {
    routes.push(getRoute(key, ds[key]))
  }
}

const files = require.context('@/router/', false, /.js$/)
files.keys().forEach(path => {
  if (!(path == './index.js' || path == './tool.js')) {
    addRoute(files(path).default)
  }
})

export default routes