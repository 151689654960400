//模块管理
export default {
  'mbgl': {
    name: '模板管理',
    vue: r => require(['../views/ga/Mold.vue'], r)
  },
  'moldhtml': {
    name: '模板管理HTML',
    vue: r => require(['../views/ga/MoldHtml.vue'], r)
  },
  'ymgl': {
    name: '页面管理',
    vue: r => require(['../views/ga/Page.vue'], r)
  },
  'pagehtml': {
    name: '页面管理HTML',
    vue: r => require(['../views/ga/PageHtml.vue'], r)
  },
  'pagehtmldmy': {
    name: '页面管理HTML代码页',
    vue: r => require(['../views/ga/PageHtmlDmy.vue'], r)
  },
  'wzsx': {
    name: '网站属性',
    vue: r => require(['../views/ga/Wzsx.vue'], r)
  },
  'wzfw': {
    name: '网站访问',
    vue: r => require(['../views/ga/Fwjl.vue'], r)
  },
  'newtplj-01': {
    name: '图片链接组',
    vue: r => require(['../views/ga/ImgCote.vue'], r)
  },
  'newtplj-02': {
    name: '图片链接管理',
    vue: r => require(['../views/ga/ImgCoteImg.vue'], r)
  },
  'lmgl': {
    name: '栏目管理',
    vue: r => require(['../views/ga/Column.vue'], r)
  },
  'wzlj-01': {
    name: '文字链接项',
    vue: r => require(['../views/ga/TxtCotex.vue'], r)
  },
  'wzlj-02': {
    name: '文字链接项',
    vue: r => require(['../views/ga/TxtCote.vue'], r)
  },
  'lmgj': {
    name: '栏目归结',
    vue: r => require(['../views/ga/Lmgj.vue'], r)
  },
  'xxgl-01': {
    name: '信息新增修改',
    vue: r => require(['../views/ga/News.vue'], r)
  },
  'xxgl-02': {
    name: '信息管理',
    vue: r => require(['../views/ga/Newss.vue'], r)
  },
  'xxqy': {
    name: '需签阅者',
    vue: r => require(['../views/ga/UsersNews.vue'], r)
  },
  'qyjl': {
    name: '签阅记录',
    vue: r => require(['../views/ga/Qyjl.vue'], r)
  },
  'newmold': {
    name: '新闻模板',
    vue: r => require(['../views/ga/NewsMold.vue'], r)
  },
  'xxgl-06': {
    name: '投稿箱',
    vue: r => require(['../views/ga/DraftNews.vue'], r)
  }
}