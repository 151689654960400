import axios from 'axios'
import qs from 'qs'
import * as vue from 'vue'

window.Am = {}
window.Vue = vue
Am.WEBURL = 'http://192.168.1.177:91/zhxc/'
Am.HOST = '/zhxc/'
Am.UPLOADURL = Am.HOST + 'upload.msw';
Am.passwordErrorNumber = 6
Am.http = axios

// 地图图层加载的IP地址

// 莆田天地图（服务器停用）
// Am.MAP_SERVER_URL = 'service.fjmap.net';

// 高德地图XYZ
// Am.MAP_SERVER_URL = 'https://webst01.is.autonavi.com/appmaptile';

// 公用天地图XYZ
// Am.MAP_SERVER_URL = 'http://t{1-7}.tianditu.com';
Am.MAP_SERVER_URL = 'https://t{1-7}.tianditu.gov.cn';
// Am.MAP_SERVER_URL = 'http://service.fjmap.net';
// 公用天地图秘钥
Am.TK = '306cbfd9d9acd0621def30a259917f86'

// 海康视频地址
Am.HIKVISON_SERVER_URL = '/othservice/hikvision/wsurl';
// if (window.location.host.indexOf('10.210.80.29') != -1) {
//     // 如果是政务外网
//     console.log('————ZWWW访问Vue————');
//     Am.HIKVISON_SERVER_URL = '172.20.80.76:6080';
//     Am.HIKVISON_SERVER_URL = 'http://172.20.80.74/hksp/hikvision/wsurl';
// }

window.Date.prototype.format = function (fmt) { // author: meizz
  var o = {
    'M+': this.getMonth() + 1, // 月份
    'd+': this.getDate(), // 日
    'h+': this.getHours(), // 小时
    'm+': this.getMinutes(), // 分
    's+': this.getSeconds(), // 秒
    'q+': Math.floor((this.getMonth() + 3) / 3), // 季度
    'S': this.getMilliseconds() // 毫秒
  }
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (this.getFullYear() + '').substr(4 - RegExp.$1.length))
  }
  for (var k in o) {
    if (new RegExp('(' + k + ')').test(fmt)) {
      fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length)))
    }
  }
  return fmt
}

/**
 * 获取绝对地址
 * @param {string|object} menuId  权限ID或权限对象或含/的地址 
 * @returns 绝对地址
 */
Am.getUrl = function (menuId) {
  if (!menuId) return ''
  if (typeof menuId == 'object') menuId = menuId.id
  let url = menuId
  if (url.indexOf(Am.HOST) == 0 || url.indexOf('/') == 0 || url.indexOf('http:') == 0 || url.indexOf('https:') == 0) {
    return url;
  }
  if (url.indexOf('.') != -1 || url.indexOf('?') != -1 || url.indexOf('/') != -1) {
    url = `${Am.HOST}${url}`
  }
  if (url.indexOf('/') === -1) {
    url = `${Am.HOST}admin/run.${url}.msw`
  }
  return url;
}

/**
 * 标记一个对象，使其永远不会转换为代理。返回对象本身。
 * @param {object} ds 
 * @returns 
 */
Am.toMarkRawRaws = function (ds) {
  if (!ds || typeof ds != 'object') return ds;
  let myds = Vue.markRaw(Vue.toRaw(ds));
  if (myds instanceof Array) {
    for (let i = 0; i < myds.length; i++) {
      myds[i] = Vue.markRaw(Vue.toRaw(myds[i]));
    }
  }
  return myds;
}

/**
 * 当前登录用户信息
 */
Am.user = (function () {
  let user, kvmenus, wzlm, tglm
  return {
    set(u, publicMenu, protectedMenu) {
      if (u.id) {
        user = u
      } else {
        for (let d in u) {
          protectedMenu = JSON.parse(d)
          user = u[d]
          break
        }
      }
      kvmenus = {}
      let rs = APP.$router.options.routes
      let fn = function (ms, supm) {
        if (!(ms && ms.length)) return
        ms.forEach(m => {
          kvmenus[m.id] = m
          for (let i = 0; i < rs.length; i++) {
            if (rs[i].name === m.id) {
              rs[i].title = m.title
              m.icon = rs[i].icon
              m.path = rs[i].path
              if (supm) m.supm = supm
              break
            }
          }
          fn(m.children, m)
        })
      }
      fn(user.role.children, null)
      fn(publicMenu)
      fn(protectedMenu)
      APP.initMenus()

      //水印
      setTimeout(function () {
        if (!Am.user.mid("watermark")) return;
        if (document.getElementById("_SY")) return;
        var systr = "",
          zhxm = Am.user.get().jobNO + "&nbsp;&nbsp;" + Am.user.get().name;
        var sy = document.createElement("div"),
          syhtml,
          tem;
        sy.className = "SY";
        sy.id = "_SY";
        syhtml = document.createElement("div");
        tem = document.createElement("span");
        tem.innerHTML = zhxm;
        syhtml.appendChild(tem);
        sy.appendChild(syhtml);
        document.getElementsByTagName("body").item(0).appendChild(sy);
        setTimeout(function () {
          var spanw = tem.clientWidth || tem.offsetWidth,
            spanh = tem.clientHeight || tem.offsetHeight,
            syhtmlw = syhtml.clientWidth || syhtml.offsetWidth,
            syhtmlh = syhtml.clientWidth || syhtml.offsetWidth,
            maxi;
          maxi = (syhtmlw * syhtmlh) / (spanw * spanh);
          for (var i = 0; i < maxi; i++) {
            systr += "<span>" + zhxm + "</span>";
          }
          syhtml.innerHTML = systr;
        }, 300);
      }, 100);
    },
    get() {
      return user
    },
    getKVMenus() {
      if (kvmenus) return kvmenus
    },
    findMenuById(menuId) {
      return kvmenus ? kvmenus[menuId] : undefined
    },
    mid(menuId) {
      return kvmenus ? kvmenus[menuId] : undefined
    },
    fg(menu, geanre) {
      if (menu && menu.children) {
        for (let i = 0; i < menu.children.length; i++) {
          if (menu.children[i].geanre == geanre) {
            return menu.children[i]
          }
        }
      }
    },
    mlx(menuId, geanre) {
      return Am.user.fg(kvmenus[menuId], geanre)
    },
    addm(menuId) {
      return Am.user.mlx(menuId, 'ADD')
    },
    delm(menuId) {
      return Am.user.mlx(menuId, 'DEL')
    },
    upm(menuId) {
      return Am.user.mlx(menuId, 'UP')
    },
    getm(menuId) {
      return Am.user.mlx(menuId, 'GET')
    },
    excm(menuId) {
      return Am.user.mlx(menuId, 'EXC')
    },
    exctom(menuId) {
      return Am.user.mlx(menuId, 'EXCTO')
    },
    subm(supId, menuId) {
      let m = kvmenus[supId];
      if (m && m.children) {
        for (let i = 0; i < m.children.length; i++) {
          if (m.children[i].id == menuId) {
            return m.children[i]
          }
        }
      }
    },
    wzlm() { //网站栏目
      if (!wzlm) {
        let lm = Am.user.mid("wzlm"),
          clFn;
        if (lm && lm.children && lm.children.length) {
          clFn = (ms) => {
            if (ms && ms.length) {
              let tems = [];
              ms.forEach(m => {
                if (m.isDraft != 1) {
                  let tem = {
                      value: m.id,
                      label: m.title,
                      leaf: true,
                    },
                    chi;
                  chi = clFn(m.children);
                  if (chi) {
                    tem.children = chi;
                    tem.leaf = false;
                  }
                  tems.push(tem);
                }
              });
              return tems.length ? tems : undefined;
            }
          }
          wzlm = clFn(lm.children);
        }
        wzlm = wzlm || [];
      }
      return wzlm;
    },
    tglm() { //投稿栏目
      if (!tglm) {
        tglm = [];
        let lm = Am.user.mid("wzlm"),
          clFn;
        if (lm && lm.children && lm.children.length) {
          clFn = (ms) => {
            if (ms && ms.length) {
              ms.forEach(m => {
                if (m.isDraft == 1) {
                  tglm.push({
                    value: m.id,
                    label: m.title,
                  });
                }
                clFn(m.children);
              });
            }
          }
          clFn(lm.children);
        }
      }
      return tglm;
    }
  }
})()
/**
 * 下载数据
 * @param {string|object} mid 权限或url
 * @param {object} query 查询条件
 */
Am.download = (function () {
  let form = document.createElement('form');
  form.style = "position:absolute;left:-99px;z-index:-1;width:0;height:0;overflow:hidden;"
  form.method = 'POST';
  form.target = '_blank';
  document.body.appendChild(form);
  return function (mid, query) {
    form.action = Am.getUrl(mid);
    let hs = [];
    for (let k in query) {
      hs.push('<input type="hidden" name="' + k + '" value="' + (query[k] || '') + '" />')
    }
    form.innerHTML = hs.join('');
    form.submit();
  };
})();
/**
 * 格式化时间为yyyy-MM-dd hh:mm:ss
 * @param {date|object} v 时间
 * @param {column} column 列
 * @returns 
 */
Am.getTime = (v, column) => {
  if (typeof v == 'string') return v;
  if (!v) v = new Date();
  if (v instanceof Date) return v.format('yyyy-MM-dd hh:mm:ss')
  if (typeof v == 'number') {
    let d = new Date()
    d.setTime(v)
    return d.format('yyyy-MM-dd hh:mm:ss')
  }
  if (column && column.property) {
    v = v[column.property]
  }
  if (!(v && v.time)) return ''
  let d = new Date()
  d.setTime(v.time)
  if (d.getMonth() !== v.month || d.getDate() !== v.date) {
    d.setDate(v.date)
    d.setMonth(v.month)
  }
  return d.format('yyyy-MM-dd hh:mm:ss')
}
/**
 * 格式化时间为yyyy-MM-dd
 * @param {date|object} v 时间
 * @param {column} column 列
 * @returns 
 */
Am.getDate = (v, column) => {
  if (typeof v == 'string') return v;
  if (v instanceof Date) return v.format('yyyy-MM-dd')
  if (column && column.property) {
    v = v[column.property]
  }
  if (!(v && v.time)) return ''
  let d = new Date()
  d.setTime(v.time)
  if (d.getMonth() !== v.month || d.getDate() !== v.date) {
    d.setDate(v.date)
    d.setMonth(v.month)
  }
  return d.format('yyyy-MM-dd')
}
/**
 * 格式化时间
 * @param {date|object} v 时间
 * @param {string} format 格式（如yyyy-MM-dd hh:mm:ss）
 * @returns 
 */
Am.dateFormat = (v, format) => {
  if (typeof v == 'string') {
    format = v;
    v = new Date();
  }
  if (!v.format) {
    v = new Date(v.getTime());
  }
  return v.format(format)
}
/**
 * 按字典处理列表的列显示值
 * @param {object} ds 字典
 * @param {string} oldv 原值
 * @param {column} column 列
 * @returns 
 */
Am.getLabel = (ds, oldv, column) => {
  if (column && column.property) {
    oldv = oldv[column.property]
  }
  for (let n in ds) {
    if (ds[n].value + '' === oldv + '') {
      return ds[n].label
    }
  }
  return ''
}

Am.getVal = (row, column, cellValue, index) => {
  if (!cellValue) return ''
  if (typeof cellValue === 'object') {
    if (cellValue.time && cellValue.date && cellValue.month) {
      if (row instanceof Date) return row.format('yyyy-MM-dd hh:mm:ss')
      if (column && column.property) {
        row = row[column.property]
      }
      if (!(row && row.time)) return ''
      let d = new Date()
      d.setTime(row.time)
      if (d.getMonth() !== row.month || d.getDate() !== row.date) {
        d.setDate(row.date)
        d.setMonth(row.month)
      }
      return d.format('yyyy-MM-dd hh:mm:ss')
    }
    return cellValue.title || cellValue.name
  }
  return cellValue || ''
}
/**
 * 以vObj给yObj赋值对象
 * @param {object} yObj 原对象
 * @param {object} vObj 赋值对象
 * @param {object} defaultObj 默认值
 * @returns 
 */
Am.setObj = (yObj, vObj, defaultObj) => {
  if (!yObj) return
  if (!vObj && defaultObj) {
    vObj = defaultObj;
    defaultObj = null;
  }
  if (!vObj) return
  for (let k in yObj) {
    yObj[k] = vObj[k]
  }
  if (defaultObj) {
    for (let k in yObj) {
      if ((yObj[k] == null || yObj[k] == undefined) && defaultObj[k]) {
        yObj[k] = defaultObj[k];
      }
    }
  }
}
/**
 * 复制数据
 * @param {object} obj 
 * @returns 复制后的新数据
 */
Am.copy = (obj) => {
  if (!obj) return null;
  let vs = {};
  for (let k in obj) {
    vs[k] = obj[k];
  }
  return vs;
}
/**
 * POST方式请求
 * @param {string} menuId 权限菜单ID
 * @param {object} args 参数
 * @param {function} okFn 成功回调方法
 * @param {function} noFn 失败回调方法
 * @param {boolean} loading 是否显示加载条默认是
 */
Am.post = function (menuId, args, okFn, noFn, loading) {
  if (typeof args === 'function') {
    loading = noFn
    noFn = okFn
    okFn = args
    args = {}
  }
  if (typeof noFn === 'boolean') {
    loading = noFn
  }
  loading = loading === false ? false : true;
  let url = Am.getUrl(menuId);
  if (loading) Am.loading.show()
  args = args || {}
  Am.http.post(url, args instanceof FormData ? args : qs.stringify(args)).then(function (response) {
    if (loading) Am.loading.hide()
    if (okFn) {
      if (response.status == 404 || (response.data && response.data.login === false)) {
        // Am.login(() => { Am.post(menuId, args, okFn, noFn) })
        window.location.href = Am.getUrl("pages/login.html?_webapp=1");
      } else {
        okFn(response.data)
      }
    }
  }, function (err) {
    if (loading) Am.loading.hide()
    Am.error('操作失败')
    if (noFn) noFn()
  })
}
/**
 * GET方式请求
 * @param {string} menuId 权限菜单ID
 * @param {function} okFn 成功回调方法
 * @param {function} noFn 失败回调方法
 * @param {boolean} loading 是否显示加载条默认是
 */
Am.get = function (menuId, okFn, noFn, loading) {
  if (typeof noFn === 'boolean') {
    loading = noFn
  }
  loading = loading === false ? false : true;
  let url = Am.getUrl(menuId);
  if (loading) Am.loading.show()
  Am.http.get(url).then(function (response) {
    if (loading) Am.loading.hide()
    if (okFn) {
      if (response.status == 404 || (response.data && response.data.login === false)) {
        // Am.login(() => { Am.get(menuId, okFn, noFn) })
        window.location.href = Am.getUrl("pages/login.html");
      } else {
        okFn(response.data)
      }
    }
  }, function (err) {
    if (loading) Am.loading.hide()
    Am.error('操作失败')
    if (noFn) noFn()
  })
}
/**
 * 加载条
 */
Am.loading = (function () {
  let sett = null
  return {
    show: function () {
      if (sett) {
        clearTimeout(sett)
        sett = null
      }
      APP.fullscreenLoading = true
    },
    hide: function () {
      if (sett) {
        clearTimeout(sett)
        sett = null
      }
      sett = setTimeout(() => {
        APP.fullscreenLoading = false
      }, 200)
    }
  }
})();

/**
 * 弹出窗口
 * @param {string} title 窗口标题
 * @param {string|component} id 权限Id或组件
 * @param {function} closeFn 窗口关闭后回调，若有返回值将传入
 * @param {object} par 数据参数，子组件可获取
 * @param {number} szts  选择条数（当szts=1只选择一条，其他多选）
 * @param {number} width 窗口宽度
 * @param {boolean} fullscreen 是否全屏
 * @param {object} winPar 窗口参数
 * @returns 
 */
Am.open = function (title, id, closeFn, par, szts, width, fullscreen, winPar) {
  let args = {
    menuId: typeof id == 'string' ? id : null,
    id: new Date().getTime() + '' + Math.floor(Math.random() * 1000),
    title: title || '信息窗口',
    top: null,
    width: width,
    szts: szts,
    isVisible: true,
    fullscreen: fullscreen || false,
    closeOnClickModal: false,
    closeOnPressEscape: false,
    destroyOnClose: true,
    showClose: true,
    closeFn: closeFn,
    isAnimation: false,
    parData: Am.toMarkRawRaws(par),
    winComponent: null
  };
  if (!winPar) winPar = {}
  args.top = winPar.top
  args.closeOnClickModal = winPar.closeOnClickModal === true
  args.closeOnPressEscape = winPar.closeOnPressEscape === true
  args.showClose = winPar.showClose !== false
  if (typeof id == 'string') {
    if (APP.$router.hasRoute(id)) {
      args.winComponent = APP.$router.resolve(id).matched[0].components.default
    }
  } else {
    args.winComponent = id
  }
  APP.wins.push(Vue.markRaw(args))
  return args
}
/**
 * 关闭当前窗口
 * @param {component} com 当前组件(this)
 * @param {object} data 传入数据
 */
Am.open.close = function (com, data) {
  if (com && com.$emit) {
    com.$emit("getWin", (win) => {
      if (win) {
        if (data) win.setRetData(Am.toMarkRawRaws(data))
        win.close()
      }
    })
  }
}
/**
 * 获取当前窗口
 * @param {component} com 当前组件(this)
 * @param {function} fn 回调方法返回参数为当前窗口
 */
Am.open.getWin = function (com, fn) {
  if (com && com.$emit && typeof fn == 'function') {
    com.$emit("getWin", (win) => {
      fn(win)
    })
  }
}
/**
 * 给当前窗口传入数据
 * @param {component} com 当前组件(this)
 * @param {object} data 传入数据
 */
Am.open.setData = function (com, data) {
  if (com && com.$emit && data) {
    com.$emit("getWinSetRetData", Am.toMarkRawRaws(data))
  }
}
/**
 * 获取父窗口传入当前窗口的数据
 * @param {component} com 当前组件(this)
 * @param {function} fn 回调方法返回参数为数据
 */
Am.open.getPar = function (com, fn) {
  if (com && com.$emit && typeof fn == 'function') {
    com.$emit("getWinParData", (data) => {
      fn(data)
    })
  }
}
/**
 * 登录窗口
 */
Am.login = (function () {
  let fns = [],
    isshow = false;
  return (fn) => {
    fns.push(fn);
    if (!isshow) {
      isshow = true;
      Am.open('登 录', '_1', () => {
        isshow = false;
        let myfns = fns;
        fns = [];
        myfns.forEach((f) => {
          if (typeof f == 'function') f();
        });
      }, null, null, 400, false, {
        closeOnClickModal: false,
        showClose: false
      })
    }
  }
})();
/**
 * 成功
 * @param {string} msg 
 */
Am.success = function (msg) {
  ElMessage.success({
    message: msg,
    type: 'success'
  });
}
/**
 * 警告
 * @param {string} msg 
 */
Am.warning = function (msg) {
  ElMessage.warning({
    message: msg,
    type: 'warning'
  });
}
/**
 * 消息
 * @param {string} msg 
 */
Am.msg = function (msg) {
  ElMessage(msg);
}
/**
 * 错误
 * @param {string} msg 
 */
Am.error = function (msg) {
  ElMessage.error(msg);
}
/**
 * 对话框
 * @param {string} msg 
 * @param {function} okFn 
 * @param {function} noFn 
 */
Am.confirm = function (msg, okFn, noFn) {
  ElMessageBox.confirm(msg, "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    })
    .then(() => {
      if (typeof okFn == 'function') okFn()
    })
    .catch(() => {
      if (typeof noFn == 'function') noFn()
    });
}
/**
 * 提示
 * @param {string} msg 
 */
Am.alert = function (msg, fn) {
  ElMessageBox.alert(msg, '提示', {
    confirmButtonText: '确定',
    callback: () => {
      if (typeof fn == 'function') fn()
    }
  });
}
/**
 * 给组件设置显示值
 * @param {component} com 组件一般为Select、Cascader
 * @param {string} value 值
 */
Am.setInputValue = function (com, value) {
  if (com && com.$el && com.$el.parentElement) {
    com.$el.parentElement.querySelector('input').value = value == undefined || value == null ? "" : value;
  }
}
/**
 * 延时给组件设置显示值
 */
Am.delayedSetInputValue = function () {
  let args = arguments;
  if (args[0] && args[0].$refs) {
    setTimeout(() => {
      for (let i = 1; i < args.length; i += 2) {
        Am.setInputValue(args[0].$refs[args[i]], args[i + 1])
      }
    }, 200)
  }
}
/**
 * 获取指定属性值树级数组值
 * @param {object} data 数据 
 * @param {string} sup 父属性名
 * @param {string} attr 取值属性名
 * @returns 如：[1,101,10101]
 */
Am.getSupAttrs = function (data, sup, attr) {
  if (!sup) return;
  attr = attr || 'id'
  if (data) {
    let vs = null
    if (data[sup]) {
      vs = Am.getSupAttrs(data[sup], sup, attr)
    }
    if (!vs) vs = []
    vs.push(data[attr])
    return vs
  }
}
/**
 * 获取组织指定属性值树级数组值
 * @param {object} org 组织机构数据
 * @returns 如：[1,101,10101]
 */
Am.getSupAttrsOrg = function (org) {
  return Am.getSupAttrs(org, 'organization')
}

/**
 * 获取组件对应权限ID
 * @param {component} com 
 * @returns 
 */
Am.getMenuId = (com) => {
  if (com) {
    return com.$props.winMenuId || com.$route.params.mid;
  }
}

/**
 * 简单加密隐私
 * @param {column} column 
 * @returns 
 */
Am.jmys = (row, column, cellValue, index) => {
  if (typeof cellValue === 'string') {
    if (cellValue.length >= 15) {
      return cellValue.substring(0, 3) + '***********' + cellValue.substring(cellValue.length - 4);
    } else {
      return cellValue.substring(0, 3) + '****' + cellValue.substring(cellValue.length - 4);
    }
  }
  return cellValue;
}