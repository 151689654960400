//人才振兴
export default {
    'rclx': {
        name: '人才类别',
        vue: r => require(['../views/rczx/Rclx.vue'], r)
    },
    'hylx': {
        name: '行业类别',
        vue: r => require(['../views/rczx/Hylx.vue'], r)
    },
    'rcgl': {
        name: '人才管理',
        vue: r => require(['../views/rczx/Rcgl.vue'], r)
    },
    'rcpxjy': {
        name: '人才培训教育',
        vue: r => require(['../views/rczx/Rcpxjy.vue'], r)
    },
    'wskt': {
        name: '网上课堂',
        vue: r => require(['../views/rczx/Wskt.vue'], r)
    },
}