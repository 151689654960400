<template>
  <div class="am-list" :class="classname">
    <div class="am-list-header">
      <slot name="header"></slot>
    </div>
    <div class="am-list-body">
      <slot name="body"></slot>
    </div>
    <div class="am-list-footer">
      <slot name="footer"></slot>
    </div>
    <slot name="other"></slot>
  </div>
</template>

<style>
.am-list {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.am-list .el-date-editor--daterange.el-input,
.am-list .el-date-editor--daterange.el-input__inner,
.am-list .el-date-editor--timerange.el-input,
.am-list .el-date-editor--timerange.el-input__inner,
.am-list .el-input-number {
  width: auto;
}
.am-list .el-select,
.am-list .el-select .el-select__tags > span,
.am-list .el-switch__core,
.am-list .el-switch__label,
.am-list .el-switch__label *,
.am-list .el-cascader,
.am-list .el-input-number {
  display: block;
}
.am-list-header {
  padding: 20px 20px 10px 20px;
}
.am-list-header {
  margin: -5px 0;
}
.am-list-header > .el-row {
  margin: 5px 0;
}
.am-list-body {
  flex: 1;
  overflow: hidden;
  padding: 0 20px;
}
.am-list-footer {
  padding: 10px 20px 20px 20px;
}
</style>

<script>
export default {
  props: ["classname"],
  data() {
    return {};
  },
};
</script>
