// 短信群发记录管理
export default {
    'txlgl-01': {
        name: '个人通讯录管理',
        vue: r => require(['../views/dxqfjlgl/Txl.vue'], r)
    },
    'txlgl-02': {
        name: '通讯单位管理',
        vue: r => require(['../views/dxqfjlgl/Txdw.vue'], r)
    },
    'txlgl-03': {
        name: '通讯职务管理',
        vue: r => require(['../views/dxqfjlgl/Txzw.vue'], r)
    },
    'txlgl-05': {
        name: '我的分组',
        vue: r => require(['../views/dxqfjlgl/MyTxlfz.vue'], r)
    },
    'dxqfdwxxgl': {
        name: '单位管理',
        vue: r => require(['../views/dxqfjlgl/DxqfDwxx.vue'], r)
    },
    'dxqfyxj': {
        name: '优先级管理',
        vue: r => require(['../views/dxqfjlgl/Dxqfyxj.vue'], r)
    },
    'smsFssj': {
        name: '免打扰发送时间设置',
        vue: r => require(['../views/dxqfjlgl/SmsFssj.vue'], r)
    },
    'jkUser': {
        name: '应用接口配置',
        vue: r => require(['../views/dxqfjlgl/JkUser.vue'], r)
    },
    'txlgl-common-02': {
        name: '通讯录公共分组',
        vue: r => require(['../views/dxqfjlgl/TxlfzCommon.vue'], r)
    },
    'txlgl-common-01': {
        name: '公共通讯录管理',
        vue: r => require(['../views/dxqfjlgl/TxlCommon.vue'], r)
    },
    'dxfz': {
        name: '手机号码分组',
        vue: r => require(['../views/dxqfjlgl/Dxfz.vue'], r)
    },
    'mydxmb': {
        name: '短信模板管理',
        vue: r => require(['../views/dxqfjlgl/MyDxmb.vue'], r)
    },
    'dxqfjl': {
        name: '短信发送',
        vue: r => require(['../views/dxqfjlgl/Dxqfjl.vue'], r)
    },
    'dxqfHistoryMy': {
        name: '我的发送记录',
        vue: r => require(['../views/dxqfjlgl/DxqfHistoryMy.vue'], r)
    },
    'dxqfSubHistoryMy': {
        name: '短信发送明细',
        vue: r => require(['../views/dxqfjlgl/DxqfSubHistoryMy.vue'], r)
    },
    'dxqfjkHistoryMy': {
        name: '我的接口记录',
        vue: r => require(['../views/dxqfjlgl/DxqfjkHistoryMy.vue'], r)
    },





    //查询与统计
    'dxqfHistory': {
        name: '短信发送记录查询',
        vue: r => require(['../views/dxqfjlgl/DxqfHistory.vue'], r)
    },
    'dxqfSubHistory': {
        name: '短信发送明细查询',
        vue: r => require(['../views/dxqfjlgl/DxqfSubHistory.vue'], r)
    },
    'dxqfjkHistory': {
        name: '接口发送记录查询',
        vue: r => require(['../views/dxqfjlgl/DxqfjkHistory.vue'], r)
    },
    'dxqfHistoryCount': {
        name: '短信发送记录统计',
        vue: r => require(['../views/dxqfjlgl/DxqfHistoryCount.vue'], r)
    },
    'dxqfjkHistoryCount': {
        name: '接口发送记录统计',
        vue: r => require(['../views/dxqfjlgl/DxqfjkHistoryCount.vue'], r)
    },
}