//专项建设
export default {
    'xmlb': {
        name: '项目类别',
        vue: r => require(['../views/zxjs/Xmlb.vue'], r)
    },
    'zxflk': {
        name: '专项分类库',
        vue: r => require(['../views/zxjs/Zxflk.vue'], r)
    },
    'zxlm': {
        name: '专项栏目',
        vue: r => require(['../views/zxjs/Zxlm.vue'], r)
    },
    'jscg': {
        name: '建设成果',
        vue: r => require(['../views/zxjs/Jscg.vue'], r)
    },
    'jscg_pl': {
        name: '建设成果(批量)',
        vue: r => require(['../views/zxjs/JscgPl.vue'], r)
    },
    'xmgl': {
        name: '项目管理',
        vue: r => require(['../views/zxjs/Xmgl.vue'], r)
    },
}