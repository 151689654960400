<template>
  <el-dialog
    :custom-class="mswDialog"
    :append-to-body="true"
    :title="args.title"
    :top="top"
    :width="width"
    :fullscreen="args.fullscreen"
    :close-on-click-modal="args.closeOnClickModal"
    :destroy-on-close="args.destroyOnClose"
    v-model="isVisible"
    :close-on-press-escape="args.closeOnPressEscape"
    :show-close="args.showClose"
    @close="winCloseFn"
    center
  >
    <component
      :is="args.winComponent"
      @getWin="getThis"
      @getWinParData="getParData"
      @getWinSetRetData="setRetData"
      :isWinSelect="!!args.szts"
      :winParData="args.parData"
      :winMenuId="args.menuId"
    />
    <template #footer v-if="!!args.szts">
      <span class="dialog-footer">
        <el-button @click="winCloseFn">取 消</el-button>
        <el-button type="primary" @click="closeOk">确 定</el-button>
      </span>
      <div class="selectData">{{ selectDataMsg }}</div>
    </template>
  </el-dialog>
</template>
<style>
div.el-overlay {
  overflow: hidden;
}
.mswDialog.el-dialog {
  display: flex;
  flex-direction: column;
  width: calc(100% - 60px);
  height: calc(100% - 60px);
}
.mswDialog.el-dialog.auto {
  height: auto;
}
.mswDialog .el-dialog__body {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.mswDialog .el-dialog__footer {
  position: relative;
}
.mswDialog .am-list {
  padding: 0;
}
.mswDialog .am-list-header {
  padding: 0px 0 10px 0;
}
.mswDialog .am-list-footer {
  padding: 10px 0 0 0;
}
.mswDialog .am-list-body {
  padding: 0px;
}
.selectData {
  position: absolute;
  left: 20px;
  top: 0;
  height: 70px;
  color: #66b1ff;
}
</style>
<script>
export default {
  props: ["args", "argsi"],
  data() {
    return {
      top: "30px",
      width: "calc(100% - 60px)",
      isVisible: true,
      mswDialog: "mswDialog",
      selectDataMsg: "请选择数据",
      isqd: false,
    };
  },
  created() {
    this.isVisible = !!this.args.isVisible;
    this.width = this.args.width ? this.args.width : null;
    this.top = this.args.width ? "15vh" : "30px";
    this.mswDialog = this.args.width ? "mswDialog auto" : "mswDialog";
    if (this.args.szts) {
      this.selectDataMsg =
        this.args.szts == 1 ? "请选择一条数据" : "请选择数据";
    }
    this.isqd = false;
  },
  methods: {
    winCloseFn() {
      this.isVisible = false;
      for (let i = 0; i < APP.wins.length; i++) {
        if (APP.wins[i].id == this.args.id) {
          setTimeout(() => {
            APP.wins.splice(i, 1);
          }, 500);
          break;
        }
      }
      if (typeof this.args.closeFn == "function") {
        this.args.closeFn(this.isqd ? this.retdata || [] : undefined);
      }
    },
    closeOk() {
      // if (this.retdata instanceof Array) {
      //   if (!this.retdata.length) {
      //     Am.warning(this.args.szts == 1 ? "请选择一条数据" : "请选择数据");
      //     return;
      //   }
      // } else if (!this.retdata) {
      //   Am.warning(this.args.szts == 1 ? "请选择一条数据" : "请选择数据");
      //   return;
      // }
      this.close();
    },
    close() {
      if (this.retdata && this.args.szts == 1 && this.retdata.length > 1) {
        let t = this.retdata[0].title || this.retdata[0].name;
        Am.confirm(
          "只需选择一条，确定要选择" + (t ? "“" + t + "”" : "第1条") + "吗？",
          () => {
            this.retdata = Am.toMarkRawRaws([this.retdata[0]]);
            this.isqd = true;
            this.isVisible = false;
          }
        );
        return;
      }
      this.isqd = true;
      this.isVisible = false;
    },
    setRetData(retdata) {
      if (retdata) {
        if (retdata instanceof Array) {
          if (retdata.length) {
            if (retdata.length == 1) {
              if (retdata[0].title || retdata[0].name) {
                this.selectDataMsg =
                  "已选择：" + (retdata[0].title || retdata[0].name);
              } else {
                this.selectDataMsg = "已选择 1 条";
              }
            } else {
              this.selectDataMsg = "已选择 " + retdata.length + " 条";
            }
          } else {
            this.selectDataMsg =
              this.args.szts == 1 ? "请选择一条数据" : "请选择数据";
          }
        } else {
          this.selectDataMsg = "已选择数据";
        }
      } else {
        this.selectDataMsg =
          this.args.szts == 1 ? "请选择一条数据" : "请选择数据";
      }
      this.retdata = retdata;
      return this;
    },
    getParData(fn) {
      if (typeof fn == "function") fn(this.args.parData);
      return this.args.parData;
    },
    getThis(fn) {
      if (typeof fn == "function") fn(this);
      return this;
    },
  },
};
</script>
