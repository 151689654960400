//公用查询
export default {
    //人员信息-公用查询
    '_ryxx': {
        name: '人员管理',
        vue: r => require(['../views/jcsj/Ryxx.vue'], r)
    },
    // 短信群发记录管理-公用查询
    'pub_menu_txlMyget': {
        name: '个人通讯录管理_查看',
        vue: r => require(['../views/dxqfjlgl/Txl.vue'], r)
    },
    'pub_menu_dxqfDwxx': {
        name: '单位信息-公用查询',
        vue: r => require(['../views/dxqfjlgl/DxqfDwxx.vue'], r)
    },
    'txlgl-01-06': {
        name: '通讯职务公用查询',
        vue: r => require(['../views/dxqfjlgl/Txzw.vue'], r)
    },
    'pub_menu_txlgl-common-01': {
        name: '公共通讯录-公用查询',
        vue: r => require(['../views/dxqfjlgl/TxlCommon.vue'], r)
    },
    'pub_menu_txlgl-05': {
        name: '我的分组-公用查询',
        vue: r => require(['../views/dxqfjlgl/MyTxlfz.vue'], r)
    },
    'pub_menu_dxfz': {
        name: '手机号码分组-公用查询',
        vue: r => require(['../views/dxqfjlgl/Dxfz.vue'], r)
    },
    'pub_menu_dxmb': {
        name: '短信模板-公用查询',
        vue: r => require(['../views/dxqfjlgl/MyDxmb.vue'], r)
    },
    'pub_menu_txdw': {
        name: '树状通讯录-个人',
        vue: r => require(['../views/dxqfjlgl/TxlMenu.vue'], r)
    },
    'pub_menu_txlfzCommon': {
        name: '树状通讯录-公共',
        vue: r => require(['../views/dxqfjlgl/TxlMenu.vue'], r)
    },
    //智能应用
    'pub_menu_znyy_szafSpjk': {
        name: '视频监控-公用查询',
        vue: r => require(['../views/znyy/SzafSpjk.vue'], r)
    },
    'pub_menu_znyy_szafZdyz': {
        name: '自定义组-公用查询',
        vue: r => require(['../views/znyy/SzafZdyz.vue'], r)
    },
    'pub_lxgh_map':{
        name: '路线规划地图设计',
        vue: r => require(['../views/xczl/LxghMap.vue'], r)
    },
}