//基础信息
export default {
  'ryxx': {
    name: '人员管理',
    vue: r => require(['../views/jcsj/Ryxx.vue'], r)
  },
  'dataTj': {
    name: '统计数据',
    vue: r => require(['../views/jcsj/Tjsj.vue'], r)
  },
  'adddataTj': {
    name: '添加统计数据',
    vue: r => require(['../views/jcsj/Addtjsj.vue'], r)
  },
  'smsDxj': {
    name: '短信机',
    vue: r => require(['../views/jcsj/SmsDxj.vue'], r)
  },
  'smsYyj': {
    name: '语音机',
    vue: r => require(['../views/jcsj/SmsYyj.vue'], r)
  },
  'smsYymb': {
    name: '语音模板',
    vue: r => require(['../views/jcsj/smsYymb.vue'], r)
  },
  'yfprybf': {
    name: '易返贫人员帮扶',
    vue: r => require(['../views/jcsj/Yfprybf.vue'], r)
  },
  'sfjl': {
    name: '随访记录',
    vue: r => require(['../views/jcsj/Sfjl.vue'], r)
  },
}