<template>
  <el-config-provider :locale="locale">
    <el-progress
      :percentage="30"
      :show-text="false"
      :stroke-width="5"
      class="mypro"
      :indeterminate="true"
      v-show="jdt"
    ></el-progress>
    <am-win v-for="(k, i) in wins" :key="'win-' + k.id" :args="k" :argsi="i"></am-win>
    <el-container v-loading.fullscreen.lock="fullscreenLoading">
      <el-aside
        :style="{
          width: asideWidth + 'px',
          overflow: 'hidden',
          transition: 'all 0.5s ease',
        }"
      >
        <div class="menu-top" :class="menuTopIcon">
          <span>{{ menuTopTitle }}</span>
        </div>
        <el-scrollbar>
          <el-menu
            default-active="2"
            class="el-menu-vertical-demo"
            @open="openFn"
            @close="closeFn"
            @select="selectFn"
            background-color="#304156"
            text-color="#bfcbd9"
            active-text-color="#409eff"
            :collapse="isCollapse"
            :unique-opened="true"
            :collapse-transition="false"
          >
            <template v-for="(m1, index1) in menus" :key="index1">
              <el-submenu v-if="m1.children && m1.children.length" :index="'1_' + index1">
                <template #title>
                  <i :class="m1.icon" v-if="m1.icon"></i>
                  <span>{{ m1.title }}</span>
                </template>
                <template v-for="(m2, index2) in m1.children" :key="index2">
                  <el-menu-item :index="m2.index">
                    <i :class="m2.icon" v-if="m2.icon"></i>
                    <span>{{ m2.title }}</span>
                  </el-menu-item>
                </template>
              </el-submenu>
              <el-menu-item :index="m1.index" v-else>
                <i :class="m1.icon" v-if="m1.icon"></i>
                <span>{{ m1.title }}</span>
              </el-menu-item>
            </template>
          </el-menu>
        </el-scrollbar>
        <transition name="submenu-animation" mode="out-in">
          <div class="menu-sub" v-show="submenu && submenu.children && submenu.children.length">
            <div class="menu-top">
              <el-button class="el-icon-arrow-left" @click="menuSubBack"></el-button>
              <span>{{ submenu.title }}</span>
            </div>
            <el-scrollbar>
              <el-menu
                default-active="2"
                class="el-menu-vertical-demo"
                @open="openFn"
                @close="closeFn"
                @select="selectFn"
                background-color="#304156"
                text-color="#bfcbd9"
                active-text-color="#409eff"
                :collapse="isCollapse"
                :unique-opened="true"
                :collapse-transition="false"
              >
                <template v-for="(m1, index1) in submenu.children" :key="index1">
                  <el-submenu v-if="m1.children && m1.children.length" :index="'3_' + index1">
                    <template #title>
                      <i :class="m1.icon" v-if="m1.icon"></i>
                      <span>{{ m1.title }}</span>
                    </template>
                    <template v-for="(m2, index2) in m1.children" :key="index2">
                      <el-submenu v-if="m2.children && m2.children.length" :index="'4_' + index2">
                        <template #title>
                          <i :class="m2.icon" v-if="m2.icon"></i>
                          <span>{{ m2.title }}</span>
                        </template>
                        <template v-for="(m3, index3) in m2.children" :key="index3">
                          <el-menu-item :index="m3.index">
                            <i :class="m3.icon" v-if="m3.icon"></i>
                            <span>{{ m3.title }}</span>
                          </el-menu-item>
                        </template>
                      </el-submenu>
                      <el-menu-item :index="m2.index" v-else>
                        <i :class="m2.icon" v-if="m2.icon"></i>
                        <span>{{ m2.title }}</span>
                      </el-menu-item>
                    </template>
                  </el-submenu>
                  <el-menu-item :index="m1.index" v-else>
                    <i :class="m1.icon" v-if="m1.icon"></i>
                    <span>{{ m1.title }}</span>
                  </el-menu-item>
                </template>
              </el-menu>
            </el-scrollbar>
          </div>
        </transition>
      </el-aside>
      <el-container>
        <el-header class="apphreader">
          <div class="header-left">
            <div class="menu-kg" @click="collapseFn">
              <i :class="[isCollapse ? 'el-icon-s-unfold' : 'el-icon-s-fold']"></i>
            </div>
          </div>
          <div class="header-center">
            <div class="header-path">
              <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item v-for="m in menupath" :key="m.id">{{ m.title }}</el-breadcrumb-item>
              </el-breadcrumb>
              <el-tooltip effect="light" :content="scpath ? '点击取消收藏' : '点击加入收藏'" placement="right">
                <i
                  class="sc"
                  :class="scpath ? 'el-icon-star-on' : 'el-icon-star-off'"
                  @click="scFn"
                ></i>
              </el-tooltip>
            </div>
          </div>
          <div class="header-right">
            <div class="header-right-body">
              <el-dropdown
                class="header-fwls dbrw_icon"
                @visible-change="dbrwGets"
                @command="rightTopFwlsClick"
                max-height="700px"
              >
                <el-tooltip effect="light" content="待办任务" placement="right">
                  <span class="el-dropdown-link">
                    <el-badge :value="dbSupNum" v-if="dbSupShow" :max="99" class="item dbSub">
                      <i class="el-icon-bell"></i>
                    </el-badge>
                    <i v-else class="el-icon-bell"></i>
                  </span>
                </el-tooltip>
                <template #dropdown>
                  <el-dropdown-menu>
                    <template v-for="item in dbrwData" :key="item.id">
                      <el-divider class="dbrw_divider" content-position="center">
                        <span class="dbrw_divider_span">{{ item.title }}</span>
                      </el-divider>
                      <template v-for="item2 in item.todos" :key="item2.id">
                        <el-dropdown-item :command="item2.menu.id" icon="el-icon-tickets">
                          <span class="dbrw_title">{{ item2.title }}</span>
                          <el-tag size="mini">{{ item2.number }}</el-tag>
                        </el-dropdown-item>
                      </template>
                    </template>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>

              <el-popover
                placement="bottom"
                :width="400"
                trigger="hover"
                @show="scShowFn"
                @hide="scHideFn"
              >
                <template #reference>
                  <div class="header-sc-tb">
                    <el-tooltip effect="light" content="我的收藏" placement="left">
                      <i class="el-icon-star-off"></i>
                    </el-tooltip>
                  </div>
                </template>
                <div class="header-sc-data">
                  <el-table :data="scData" :show-header="false" :max-height="500">
                    <el-table-column
                      label="链接名称"
                      prop="title"
                      width="300"
                      :show-overflow-tooltip="true"
                    >
                      <template #="scope">
                        <el-button
                          type="text"
                          size="medium"
                          @click="selectFn(scope.row.menu.id)"
                          :style="
                            scope.row.del
                              ? { 'text-decoration': 'line-through' }
                              : ''
                          "
                        >{{ scope.row.menu.title }}</el-button>
                      </template>
                    </el-table-column>
                    <el-table-column label="操作" width="100">
                      <template #="scope">
                        <el-tooltip :content="scope.row.del ? '加入收藏' : '取消收藏'" placement="left">
                          <el-button
                            type="text"
                            :icon="
                              scope.row.del
                                ? 'el-icon-star-off'
                                : 'el-icon-star-on'
                            "
                            @click="scope.row.del = !scope.row.del"
                          ></el-button>
                        </el-tooltip>
                        <el-tooltip content="上移">
                          <el-button icon="el-icon-top" type="text" @click="topScFn(scope.row)"></el-button>
                        </el-tooltip>
                        <el-tooltip content="下移" placement="right">
                          <el-button
                            icon="el-icon-bottom"
                            type="text"
                            @click="bottomScFn(scope.row)"
                          ></el-button>
                        </el-tooltip>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </el-popover>
              <el-dropdown class="header-fwls" @command="rightTopFwlsClick">
                <el-tooltip effect="light" content="历史访问" placement="left">
                  <span class="el-dropdown-link">
                    <i class="el-icon-time"></i>
                  </span>
                </el-tooltip>
                <template #dropdown>
                  <el-dropdown-menu>
                    <template v-for="(url, index) in historyurl" :key="url.id">
                      <el-dropdown-item
                        :command="url.id"
                        v-if="index > 0"
                      >[{{ url.time }}] {{ url.title }}</el-dropdown-item>
                    </template>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
              <el-tooltip effect="light" :content="'首页'" placement="bottom">
                <div class="header-sy" @click="syFn">
                  <i class="el-icon-s-home"></i>
                </div>
              </el-tooltip>
              <el-tooltip effect="light" :content="isQp ? '退出全屏' : '全屏'" placement="bottom">
                <div class="header-qp" @click="qpFn">
                  <i class="el-icon-full-screen"></i>
                </div>
              </el-tooltip>
              <el-dropdown @command="rightTopMenuClick">
                <el-button type="primary">
                  <span class="none"></span>
                  {{ userName
                  }}
                  <i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                <template #dropdown>
                  <el-dropdown-menu>
                    <template v-for="(c, index) in rtmenus" :key="index">
                      <el-dropdown-item
                        :command="c.command"
                        :icon="c.icon"
                        v-if="c.show"
                      >{{ c.title }}</el-dropdown-item>
                    </template>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </div>
          </div>
        </el-header>
        <el-main>
          <router-view v-slot="{ Component }">
            <transition name="component-animation" mode="out-in">
              <keep-alive max="20">
                <component :is="Component" />
              </keep-alive>
            </transition>
          </router-view>
        </el-main>
      </el-container>
    </el-container>
  </el-config-provider>
</template>

<style>
html,
body {
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: 0;
  margin: 0;
  text-align: left;
  font-size: 14px;
}
img.el-image__inner {
  width: auto;
}
.el-drawer .el-drawer__header {
  font-size: 16px;
}
.el-scrollbar .el-scrollbar__wrap {
  overflow-x: hidden;
}
.el-scrollbar .el-scrollbar__wrap.el-select-dropdown__wrap {
  overflow-x: scroll;
}
.el-scrollbar {
  height: 100%;
  width: 100%;
}
.el-scrollbar .el-scrollbar__wrap .el-scrollbar__view {
  white-space: nowrap;
}
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
div.el-loading-mask {
  background-color: rgba(255, 255, 255, 0.5);
}
.el-progress.mypro {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 999999;
}
.mypro .el-progress-bar__outer {
  background: none;
}
.win-enter-active,
.win-leave-active {
  transition: opacity 0.3s ease;
}
.win-enter,
.win-leave-to {
  opacity: 0;
  transform: translateX(-100px);
}
.list-enter-active,
.list-leave-active {
  transition: all 0.5s;
}
.list-enter,
.list-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
.component-animation-enter-active {
  width: 100%;
  height: 100%;
  overflow: hidden;
  opacity: 0;
  transform: translateX(200px);
  transition: all 0.5s;
}
.component-animation-leave-active {
  width: 100%;
  height: 100%;
  overflow: hidden;
  transition: all 0.5s;
}
.component-animation-enter-to {
  opacity: 1;
  transform: translateX(0px);
}
.component-animation-leave-to {
  opacity: 0;
  transform: translateX(-200px);
}
.submenu-animation-enter-active {
  width: 100%;
  height: 100%;
  overflow: hidden;
  opacity: 0;
  transform: translateX(200px);
  transition: all 0.5s;
}
.submenu-animation-leave-active {
  width: 100%;
  height: 100%;
  overflow: hidden;
  transition: all 0.5s;
}
.submenu-animation-enter-to {
  opacity: 1;
  transform: translateX(0px);
}
.submenu-animation-leave-to {
  opacity: 0;
  transform: translateX(200px);
}
.el-container {
  height: 100%;
}
.el-aside {
  background-color: #304156;
  overflow: hidden;
  z-index: 2;
  display: flex;
  flex-direction: column;
  position: relative;
}
.el-submenu .el-menu-item,
.el-submenu .el-submenu .el-submenu__title {
  background-color: #1f2d3d !important;
}
.el-submenu .el-menu-item:hover,
.el-submenu .el-submenu .el-submenu__title:hover {
  background-color: #001528 !important;
}
div.menu-top {
  background-color: #2b2f3a;
  height: 50px;
  overflow: hidden;
  color: #fff;
  line-height: 50px;
  padding-left: 17px;
  text-align: left;
  font-size: 30px;
  font-weight: bold;
  position: relative;
}
.menu-top span {
  position: absolute;
  font-size: 18px;
  padding-left: 17px;
}
.menu-top .el-button:focus,
.menu-top .el-button:hover {
  border: none;
  background: none;
}
.menu-sub .menu-top span {
  padding-left: 8px;
}
#app .el-menu {
  border: none;
  padding: 0 !important;
}
.apphreader.el-header {
  height: 50px !important;
  padding: 0 !important;
  z-index: 1;
  border-bottom: 1px solid #e4e7ed;
  background-color: #2b2f3a;
  color: rgb(191, 203, 217);
}
.apphreader.el-header span,
.apphreader.el-header span.el-breadcrumb__inner,
.apphreader.el-header span.el-breadcrumb__inner:hover {
  color: rgb(191, 203, 217);
}
#app .el-main {
  padding: 0 !important;
  position: relative;
  display: flex;
  height: 10px;
  overflow: hidden;
}
.apphreader.el-header {
  display: flex;
}
.apphreader.el-header > div {
  height: 100%;
}
.header-left {
  width: 60px;
}
.menu-kg {
  width: 60px;
  height: 49px;
  font-size: 30px;
  line-height: 50px;
  text-align: center;
  cursor: pointer;
}
.menu-kg:hover {
  color: #409eff;
}
.header-center {
  flex: 1;
  width: 10px;
  overflow: hidden;
}
.header-right {
  position: relative;
  white-space: nowrap;
}
.header-right-body {
  display: flex;
  height: 49px;
  padding: 0 5px 0 10px;
}
.header-right-body > div {
  height: 100%;
}
.header-right .el-button {
  margin-top: 4px;
  padding: 0px 5px 0px 0px;
  border: none;
  background: none !important;
  list-style: 30px;
}
.header-right .el-button span {
  height: 34px;
  float: left;
}
.header-right .el-button span span.none {
  border-radius: 17px;
  width: 34px;
  font-size: 0;
  background: url(assets/img/test.gif) no-repeat center center !important;
  background-size: auto 100% !important;
  border: solid 1px #eee;
  margin-right: 5px;
}
.header-right .el-dropdown:hover .el-button span span.none {
  border: solid 1px #409eff;
}
.header-right .el-button span i {
  display: inline-block;
}
.header-right .el-button .el-icon--right {
  margin-top: 12px;
}
.header-right .el-button.el-button--primary {
  color: #606266 !important;
}
.header-right .el-dropdown:hover .el-button.el-button--primary {
  color: #409eff !important;
}
.header-path {
  position: relative;
  width: 100%;
  height: 100%;
  white-space: nowrap;
  overflow: hidden;
  z-index: 1;
}
.header-fwls {
  margin-right: 10px;
  font-weight: bold;
  line-height: 48px;
  padding: 0 5px;
}
.header-fwls .el-icon-time {
  font-size: 24px;
  line-height: 48px;
}
.header-fwls:hover .el-icon-time {
  color: #409eff;
}
.header-fwls:hover .el-icon-bell {
  color: #409eff;
}
.header-fwls .el-icon-bell {
  font-size: 24px;
  line-height: 48px;
}
.header-qp,
.header-sc-tb {
  margin-right: 20px;
  font-size: 24px;
  font-weight: bold;
  line-height: 48px;
}
.header-sc-tb {
  margin-right: 10px;
}
.header-sc-tb:hover,
.header-qp:hover {
  color: #409eff;
}
.header-sy {
  margin-right: 10px;
  font-size: 24px;
  font-weight: bold;
  line-height: 48px;
}
.header-sy:hover {
  color: #409eff;
}
.header-sc-data .el-table td,
.header-sc-data .el-table th {
  padding: 2px 0;
}
.header-path .el-breadcrumb {
  float: left;
  vertical-align: middle;
  margin-top: 17px;
}
.header-path i.sc {
  float: left;
  padding: 0 5px;
  margin: 17px 0 0 10px;
  font-size: 16px;
  cursor: pointer;
}
.header-path i.el-icon-star-on {
  color: #409eff;
}
.el-form-item .el-date-editor--daterange.el-input,
.el-form-item .el-date-editor--daterange.el-input__inner,
.el-form-item .el-date-editor--timerange.el-input,
.el-form-item .el-date-editor--timerange.el-input__inner {
  width: 100%;
}
.el-form-item .el-date-editor.el-input,
.el-form-item .el-date-editor.el-input__inner {
  width: 100%;
}
.menu-sub {
  position: absolute;
  display: flex;
  flex-direction: column;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #304156;
  z-index: 1;
}
.menu-sub .menu-top {
  padding-left: 6px;
}
.menu-top button {
  color: #fff;
  height: 100%;
  padding: 0 10px;
  background: none;
  border: 0;
  line-height: 100%;
  font-size: 30px;
}
.el-form-item__error {
  z-index: 99;
}
.el-drawer__body {
  overflow: auto;
}
.el-badge__content {
  top: 15px !important;
  font-size: 1px !important;
  border: none !important;
}
.dbrw_icon {
  margin-right: 14px !important;
}
.dbrw_title {
  display: inline-block;
  width: 150px;
  margin-right: 7px;
}
.dbrw_divider {
  margin: 12px 0 !important;
}
.dbrw_divider_span {
  font-weight: bold;
}
.el-badge__content--primary {
  font-size: 12px !important;
}
/* 水印样式 */
body div.SY {
  width: 100%;
  height: 100%;
  overflow: hidden;
  pointer-events: none;
  z-index: 99999;
  display: block;
  position: absolute;
  text-align: left;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  left: 0;
  top: 0;
}
body div.SY div {
  width: 130%;
  height: 100%;
}
body div.SY span {
  float: left;
  padding: 30px 30px 30px 30px;
  line-height: 8em;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  font-size: 18px;
  color: #000;
  text-shadow: 1px 1px 2px #fff;
  opacity: 0.1;
}
</style>
<script>
import { ElConfigProvider } from "element-plus";
import zhCn from "element-plus/lib/locale/lang/zh-cn"; //国际化
export default {
  components: {
    [ElConfigProvider.name]: ElConfigProvider //添加组件
  },
  data() {
    return {
      locale: zhCn,
      userName: "",
      wins: [],
      isCollapse: false,
      menuTopIcon: "el-icon-menu",
      menuTopTitle: "加载中……",
      asideWidth: 200,
      isQp: false,
      paths: [],
      fullscreenLoading: false,
      jdt: false,
      jdtCloseSetT: null,
      currentRouteName: null,
      rtmenus: [
        { command: "01", show: true, icon: "el-icon-lock", title: "修改密码" },
        {
          command: "02",
          show: false,
          icon: "el-icon-refresh-right",
          title: "切换用户"
        },
        {
          command: "03",
          show: false,
          icon: "el-icon-document-checked",
          title: "生成主页"
        },
        {
          command: "04",
          show: true,
          icon: "el-icon-document",
          title: "登录记录"
        },
        { command: "05", show: true, icon: "el-icon-user", title: "我的信息" },
        {
          command: "06",
          show: true,
          icon: "el-icon-switch-button",
          title: "退出登录"
        }
      ],
      menus: [],
      submenu: { title: "加载中……", children: [], index: "" },
      menuKV: {},
      menupath: [],
      menupathSetT: null,
      historyurl: [],
      dbrwData: [],
      dbSupNum: 0,
      dbSupShow: false,
      scpath: false,
      scData: [],
      oldScData: [],
      upscT: null
    };
  },
  created() {
    window.APP = this;
    this.$router.beforeEach((to, from) => {
      if (!Am.user.get()) {
        this.currentRouteName = to.name;
        return false;
      }
      this.currentRouteName = null;
      this.menupath.length = 0;
      this.setMenupath(Am.user.mid(to.name));
    });
  },
  mounted() {
    setTimeout(() => {
      Am.get("admin/fzam_login_user.msw", () => {
        Am.post("_1", { ACTION: "INFO" }, ret => {
          if (ret.success) {
            if (ret.data) {
              Am.user.set(ret.data);
              if (Am.user.mid("_24")) {
                this.rtmenus[1].show = true;
              }
              if (Am.user.mid("generate")) {
                this.rtmenus[2].show = true;
              }
              this.userName = Am.user.get().name;
              if (this.$router.currentRoute) {
                this.setMenupath(
                  Am.user.mid(this.$router.currentRoute._value.name)
                );
              }
              if (this.currentRouteName) {
                this.$router.push({
                  name: this.currentRouteName,
                  params: { mid: this.currentRouteName }
                });
              }
            } else {
              Am.get("admin/fzam_login_user.msw", () => {
                location.reload();
              });
            }
            Am.post("yhscqx", { ACTION: "user" }, ret => {
              this.scData = [];
              let m = null;
              (ret.data || []).forEach(d => {
                m = Am.user.mid(d.menu.id);
                if (m) {
                  d.menu.title = Am.getSupAttrs(m, "supm", "title").join("/");
                  this.scData.push(d);
                }
              });
              let selmid =
                this.menupath && this.menupath.length
                  ? this.menupath[this.menupath.length - 1].id
                  : null;
              this.oldScData = this.scData.map(v => {
                if (!this.scpath && selmid) {
                  if (v.menu.id == selmid) this.scpath = true;
                }
                return v.menu.id;
              });
            });
            //拉取待办任务
            this.dbrwGets(true);
          } else {
            Am.error(ret.msg || "请求失败");
          }
        });
      });
    }, 200);
  },
  methods: {
    setMenupath(m) {
      if (!m) return;
      if (m.supm) {
        this.setMenupath(m.supm);
      }
      this.menupath.push(m);
      if (this.menupathSetT) {
        clearTimeout(this.menupathSetT);
        this.menupathSetT = null;
      }
      this.menupathSetT = setTimeout(() => {
        let m = this.menupath[this.menupath.length - 1],
          val;
        this.historyurl.forEach((v, i) => {
          if (v.id == m.id) {
            val = v;
            val.time = Am.dateFormat("hh:mm:ss");
            this.historyurl.splice(i, 1);
            return;
          }
        });
        if (!val) {
          val = {
            id: m.id,
            title: this.menupath
              .map(v => {
                return v.title;
              })
              .join("/"),
            time: Am.dateFormat("hh:mm:ss")
          };
        }
        this.historyurl.unshift(val);
        if (this.historyurl.length > 11) this.historyurl.length = 11;
        this.scpath = false;
        this.scData.forEach(v => {
          if (!this.scpath && m.id == v.menu.id) {
            this.scpath = true;
          }
        });
      }, 500);
    },
    initMenus() {
      let loc = window.location + "";
      let mi = loc.indexOf("?showmenuid=");
      let winpars = {};
      if (mi !== -1 && loc.indexOf("showmenuid") !== -1) {
        var params = loc.substring(loc.indexOf("?") + 1);
        var paramArr;
        if (params && params.indexOf("&") !== -1) {
          paramArr = params.split("&");
        } else {
          paramArr = [params];
        }
        var myp;
        for (var i = 0, len = paramArr.length; i < len; i++) {
          myp = paramArr[i].split("=");
          winpars[myp[0]] = myp[1];
        }
        loc = loc.substring(0, mi) + "/";
      }

      this.menus.length = 0;
      let user = Am.user.get();
      if (!user && !user.role) return;
      if (user.role.children && user.role.children.length) {
        let _this = this;
        let fn = function(m) {
          if (!m || m.display !== "1") return;
          let v = { title: m.title, icon: m.icon, index: m.id };
          if (m.children && m.children.length) {
            m.children.forEach(e => {
              let tem = fn(e);
              if (tem) {
                if (!v.children) v.children = [];
                v.children.push(tem);
              }
            });
          }
          if (!v.icon) {
            if (v.children && v.children.length) {
              v.icon = "el-icon-folder";
            } else {
              v.icon = "el-icon-document";
            }
          }
          _this.menuKV[v.index] = v;
          return v;
        };
        let menus = user.role.children;
        if (winpars.showmenuid) {
          let getM = function(ms) {
            if (ms && ms.length) {
              for (let i = 0; i < ms.length; i++) {
                if (ms[i].id === winpars.showmenuid) return ms[i];
                let m = getM(ms[i].children);
                if (m) return m;
              }
            }
          };
          let mym = getM(user.role.children);
          if (mym) {
            if (mym.icon) this.menuTopIcon = mym.icon;
            this.menuTopTitle = mym.title;
            menus = mym.children;
          }
        } else {
          this.menuTopIcon = "el-icon-menu";
          this.menuTopTitle = "后台管理";
        }
        menus.forEach(e => {
          let menu = fn(e);
          if (menu) this.menus.push(menu);
        });
      }
    },
    openFn(key, keyPath) {
      // console.log(key, keyPath)
    },
    closeFn(key, keyPath) {
      // console.log(key, keyPath)
    },
    dbrwGets(k) {
      if (k) {
        Am.post(
          "dblw",
          { msw: "dblw" },
          ret => {
            if (ret.success && ret.data) {
              this.dbrwData = ret.data;
              let num = 0;
              ret.data.forEach(v => {
                if (v.todos.length > 0) {
                  v.todos.forEach(_v => {
                    if (_v.number) {
                      num += _v.number;
                    }
                  });
                }
              });
              if (num > 0) {
                this.dbSupNum = num;
                this.dbSupShow = true;
              } else {
                this.dbSupShow = false;
              }
            }
          },
          null,
          false
        );
      }
    },
    rightTopFwlsClick(command) {
      this.$router.push({ name: command, params: { mid: command } });
    },
    rightTopMenuClick(command) {
      if (command === "01") {
        // 修改密码
        Am.open("修改密码", "_3", null, null, null, 400);
      } else if (command === "02") {
        Am.open(
          "请选择要切换的用户",
          "_8",
          ret => {
            if (ret && ret.length) {
              if (ret[0].id == Am.user.get().id) {
                Am.error("请勿选择自身");
                return;
              }

              // Am.confirm("确定要切换到“" + ret[0].name + "”用户吗？", () => {
              //   Am.post("_24", { id: ret[0].id }, (ret) => {
              //     if (ret.msg) {
              //       Am.alert(ret.msg, () => {
              //         window.location.reload();
              //       });
              //     } else {
              //       window.location.reload();
              //     }
              //   });
              // });

              Am.post("_24", { id: ret[0].id }, ret => {
                if (ret.msg) {
                  Am.alert(ret.msg, () => {
                    window.location.reload();
                  });
                } else {
                  window.location.reload();
                }
              });
            }
          },
          { 
            state: 1,
            ACTION:'switch'
             },
          1
        );
      } else if (command === "03") {
        Am.get("generate", ret => {
          if (ret.success) {
            Am.success(ret.msg || "生成成功");
          } else {
            Am.error(ret.msg || "生成失败");
          }
        });
      } else if (command === "04") {
        // 登录记录
        Am.open("登录记录", "_27");
      } else if (command === "05") {
        // 我的信息
        Am.open("我的信息", "_1_wdxx", null, null, null, 400, null, {
          closeOnClickModal: true
        });
      } else if (command === "06") {
        // 退出登录
        Am.confirm("确定要退出登录吗？", () => {
          Am.post("_2", {}, ret => {
            window.location.reload();
          });
        });
      } else {
        //this.$router.push("_3");
      }
    },
    selectFn(key, keyPath, el) {
      if (this.$router.hasRoute(key)) {
        this.$router.push({ name: key, params: { mid: key } });
      } else if (this.menuKV[key]) {
        this.submenu = this.menuKV[key];
      }
    },
    jdtShow() {
      if (this.jdtCloseSetT) {
        clearTimeout(this.jdtCloseSetT);
        this.jdtCloseSetT = null;
      }
      this.jdt = true;
    },
    jdtClose() {
      if (this.jdtCloseSetT) {
        clearTimeout(this.jdtCloseSetT);
        this.jdtCloseSetT = null;
      }
      this.jdtCloseSetT = setTimeout(() => {
        this.jdt = false;
      }, 500);
    },
    qpFn() {
      let element = document.documentElement;
      if (this.isQp) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      } else {
        // 否则，进入全屏
        if (element.requestFullscreen) {
          element.requestFullscreen();
        } else if (element.webkitRequestFullScreen) {
          element.webkitRequestFullScreen();
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen();
        } else if (element.msRequestFullscreen) {
          element.msRequestFullscreen(); // IE11
        }
      }
      this.isQp = !this.isQp;
    },
    syFn() {
      Am.confirm("确定要进入首页吗？", () => {
        window.open(Am.getUrl("pages/index.html"), "_top");
        //  window.open(  "http://192.168.1.121:81/zhxc/pages/index.html",  "_top"  );
      });
    },
    collapseFn() {
      setTimeout(
        () => {
          this.isCollapse = !this.isCollapse;
        },
        this.isCollapse ? 0 : 510
      );
      this.asideWidth = this.isCollapse ? 200 : 64;
    },
    menuSubBack() {
      this.submenu = { children: [] };
    },
    scShowFn() {
      if (this.upscT) {
        clearTimeout(this.upscT);
        this.upscT = null;
      }
    },
    scHideFn() {
      if (this.upscT) {
        clearTimeout(this.upscT);
        this.upscT = null;
      }
      this.upscT = setTimeout(() => {
        let isup = false,
          vals = [];
        this.scData.forEach((v, i) => {
          if (!v.del) {
            vals.push(v);
          }
        });
        if (this.scData.length != vals.length) {
          this.scData = vals;
          if (this.scpath) {
            this.scData.forEach(v => {
              if (v.menu.id == this.menupath[this.menupath.length - 1].id) {
                isup = true;
              }
            });
            this.scpath = isup;
          }
        }
        isup = false;
        if (this.scData.length == this.oldScData.length) {
          this.scData.forEach((d, i) => {
            if (isup) return;
            if (d.menu.id != this.oldScData[i]) {
              isup = true;
              return;
            }
          });
        } else {
          isup = true;
        }
        if (isup) {
          Am.post(
            "yhscqx_03",
            {
              ACTION: "up",
              uids: JSON.stringify(
                this.scData.map(v => {
                  return v.menu.id;
                })
              )
            },
            ret => {
              if (ret.success) {
                this.oldScData = this.scData.map(v => {
                  return v.menu.id;
                });
              }
            },
            null,
            false
          );
        }
      }, 1000);
    },
    scFn() {
      let showm = Am.user.mid(this.menupath[this.menupath.length - 1].id),
        isdel = false;
      this.scData.forEach((m, i) => {
        if (m.menu.id == showm.id) {
          this.scData.splice(i, 1);
          this.scpath = false;
          isdel = true;
          return;
        }
      });
      if (!isdel) {
        if (this.scData.length >= 20) {
          Am.error("最多只能收藏20条");
          return;
        }
        this.scData.push({
          menu: {
            id: showm.id,
            title: Am.getSupAttrs(showm, "supm", "title").join("/")
          }
        });
        this.scpath = true;
      }
      this.scHideFn();
    },
    topScFn(row) {
      let oki = -1,
        tem;
      this.scData.forEach((v, i) => {
        if (v.menu.id == row.menu.id) {
          oki = i;
        }
      });
      if (oki != -1 && oki != 0) {
        tem = this.scData[oki - 1];
        this.scData[oki - 1] = this.scData[oki];
        this.scData[oki] = tem;
      }
    },
    bottomScFn(row) {
      let oki = -1,
        tem;
      this.scData.forEach((v, i) => {
        if (v.menu.id == row.menu.id) {
          oki = i;
        }
      });
      if (oki != -1 && oki != this.scData.length - 1) {
        tem = this.scData[oki];
        this.scData[oki] = this.scData[oki + 1];
        this.scData[oki + 1] = tem;
      }
    }
  }
};
document.addEventListener("fullscreenchange", function(e) {
  if (document.fullscreenElement) {
    APP.isQp = true;
  } else {
    APP.isQp = false;
  }
});
</script>
