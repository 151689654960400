//智能应用
export default {
    'znyy_szafSpjk': {
        name: '视频监控',
        vue: r => require(['../views/znyy/SzafSpjk.vue'], r)
    },
    'znyy_szafZdyz': {
        name: '自定义组',
        vue: r => require(['../views/znyy/SzafZdyz.vue'], r)
    },
    'znyy_szafFzsp': {
        name: '分组视频',
        vue: r => require(['../views/znyy/SzafFzsp.vue'], r)
    },
    
}