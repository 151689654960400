<template>
  <iframe
    ref="ued"
    src="/wangEditor/index.html"
    width="100%"
    height="100%"
    frameborder="0"
    scrolling="auto"
  ></iframe>
</template>
<script>
export default {
  props: ["modelValue"],
  data() {
    return {
      issz: false,
    };
  },
  activated() {
    this.initFn();
  },
  mounted() {
    this.initFn();
  },
  methods: {
    initFn() {
      this.$refs.ued.contentWindow.contenthtml = this.modelValue;
      let ued = this.$refs.ued,
        seti,
        goFn;
      goFn = (ret) => {
        ued.contentWindow.newsmolds = ret || [];
        seti = setInterval(() => {
          if (ued.contentWindow.EDITOR) {
            clearInterval(seti);
            ued.contentWindow.document.body.addEventListener(
              "DOMNodeInserted",
              () => {
                if (this.issz) {
                  this.issz = false;
                  return;
                }
                clearTimeout(seti);
                seti = setTimeout(() => {
                  if (this.modelValue != ued.contentWindow.EDITOR.txt.html()) {
                    this.$emit(
                      "update:modelValue",
                      ued.contentWindow.EDITOR.txt.html()
                    );
                  }
                }, 200);
              }
            );
          }
        }, 300);
      };
      Am.get(
        "newsMolds.msw",
        (ret) => {
          goFn(ret);
        },
        () => {
          goFn([]);
        }
      );
    },
  },
  watch: {
    modelValue(newv, oldv) {
      this.$refs.ued.contentWindow.contenthtml = this.modelValue;
      if (this.$refs.ued.contentWindow.EDITOR) {
        if (this.$refs.ued.contentWindow.EDITOR.txt.html() != newv) {
          this.issz = true;
          this.$refs.ued.contentWindow.EDITOR.txt.html(newv || "");
        }
      }
    },
  },
};
</script>