//乡村治理
export default {
    'ptlx': {
        name: '配套类型',
        vue: r => require(['../views/xczl/Ptlx.vue'], r)
      },
      'sslx': {
        name: '设施类型',
        vue: r => require(['../views/xczl/Sslx.vue'], r)
      },
      'dwlx': {
        name: '设施类型',
        vue: r => require(['../views/xczl/Dwlx.vue'], r)
      },
      //人员信息-公用查询
      'xcxx_cx': {
        name: '乡村信息公用查询',
        vue: r => require(['../views/xczl/Xcxx.vue'], r)
      },
      'xcxx': {
        name: '乡村信息',
        vue: r => require(['../views/xczl/Xcxx.vue'], r)
      },
      'jjqk': {
        name: '经济情况',
        vue: r => require(['../views/xczl/Jjqk.vue'], r)
      },
      'jcjspt': {
        name: '基础建设配套',
        vue: r => require(['../views/xczl/Jcjspt.vue'], r)
      },
      'jcsssb': {
        name: '基础设施设备',
        vue: r => require(['../views/xczl/Jcsssb.vue'], r)
      },
      'zzdw': {
        name: '基础设施设备',
        vue: r => require(['../views/xczl/Zzdw.vue'], r)
      },
      'dwlx': {
        name: '队伍类型',
        vue: r => require(['../views/xczl/Dwlx.vue'], r)
      },
      'zzdw': {
        name: '综治队伍',
        vue: r => require(['../views/xczl/Zzdw.vue'], r)
      },
      'dxdd': {
        name: '短信调度',
        vue: r => require(['../views/xczl/Dxdd.vue'], r)
      },
      'yydd': {
        name: '语音调度',
        vue: r => require(['../views/xczl/Yydd.vue'], r)
      },
      'rwgl_rwxf': {
        name: '任务下发',
        vue: r => require(['../views/xczl/Rwglrwxf.vue'], r)
      }, 
      'rwgl_tc_ja': {
        name: '弹窗_结案',
        vue: r => require(['../views/xczl/Rwgljatc.vue'], r)
      }, 
      'rwgl_tc_ja_xzfkxx': {
        name: '弹窗_结案_选择反馈信息',
        vue: r => require(['../views/xczl/Rwgljatcxzfkxx.vue'], r)
      },
      'rwgl_rwsh': {
        name: '任务审核',
        vue: r => require(['../views/xczl/Rwglrwsh.vue'], r)
      }, 
      'rwgl_tc_sh': {
        name: '弹窗_审核',
        vue: r => require(['../views/xczl/Rwglshtc.vue'], r)
      },
      'rwgl_rwcx': {
        name: '任务查询',
        vue: r => require(['../views/xczl/Rwglrwcx.vue'], r)
      },
      'rwgl_rwfk': {
        name: '任务反馈',
        vue: r => require(['../views/xczl/Rwglrwfk.vue'], r)
      },
      'lljjq': {
        name: '邻里家家亲',
        vue: r => require(['../views/xczl/Lljjq.vue'], r)
      },
      'jcsssbSjjl': {
        name: '预警提醒',
        vue: r => require(['../views/xczl/JcsssbSjjl.vue'], r)
      },
      'hikvisionEventType': {
        name: '视频事件类型',
        vue: r => require(['../views/xczl/HikvisionEventType.vue'], r)
      },
      'lxgh': {
        name: '路线规划信息',
        vue: r => require(['../views/xczl/Lxgh.vue'], r)
      },
      'wglx': {
        name: '活动类型',
        vue: r => require(['../views/xczl/Wglx.vue'], r)
      },
      'wggl': {
        name: '网格管理',
        vue: r => require(['../views/xczl/Wggl.vue'], r)
      },
}