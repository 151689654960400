import { createApp } from 'vue'
import './assets/js/Base64'
import './assets/js/pwdjm'
import './assets/js/sm3'
import './tool'
import { createRouter, createWebHistory } from 'vue-router'
import ElementPlus from 'element-plus'
import { ElMessage, ElMessageBox } from 'element-plus'
import App from './App.vue'
import routes from './router'
import store from './store'
import components from './components'

import 'element-plus/lib/theme-chalk/index.css';
import './assets/css/iconfont.css'

let app = createApp(App).use(store).use(createRouter({
    history: createWebHistory('/h'),
    routes
})).use(ElementPlus, { /*size: 'small'*/ })
// 注册到组件中
app.config.globalProperties.Am = Am
// 注册全局组件
for (const key in components) {
    app.component('am-' + key, components[key])
}
window.APP = app.mount('#app');

window.ElMessage = ElMessage
window.ElMessageBox = ElMessageBox

Am.jdtShow = APP.jdtShow
Am.jdtClose = APP.jdtClose