//系统管理
export default {
  '_1': {
    name: '登录',
    vue: r => require(['../views/admin/Login.vue'], r)
  },
  '_3': {
    name: '修改密码',
    vue: r => require(['../views/admin/UpPassword.vue'], r)
  },
  '_8': {
    name: '查询用户',
    vue: r => require(['../views/admin/Userses.vue'], r)
  },
  '_1_wdxx': {
    name: '我的信息',
    vue: r => require(['../views/admin/Wdxx.vue'], r)
  },
  '_27': {
    name: '我的登录记录',
    vue: r => require(['../views/admin/MyLoginRecord.vue'], r)
  },
  '_400': {
    name: '结构分组',
    icon: 'el-icon-coin',
    vue: r => require(['../views/admin/OrganizationGroup.vue'], r)
  },
  '_401': {
    name: '组织结构',
    vue: r => require(['../views/admin/Organizations.vue'], r)
  },
  '_402': {
    name: '职务管理',
    vue: r => require(['../views/admin/Duties.vue'], r)
  },
  '_403': {
    name: '权限菜单',
    vue: r => require(['../views/admin/Menu.vue'], r)
  },
  '_404': {
    name: '角色管理',
    vue: r => require(['../views/admin/Role.vue'], r)
  },
  'rolemenu': {
    name: '角色权限',
    vue: r => require(['../views/admin/RoleMenu.vue'], r)
  },
  '_405': {
    name: '用户管理',
    vue: r => require(['../views/admin/Userses.vue'], r)
  },
  'usersfzs': {
    name: '用户分组',
    vue: r => require(['../views/admin/UsersFz.vue'], r)
  },
  '_406': {
    name: '主题管理',
    vue: r => require(['../views/admin/Themeses.vue'], r)
  },
  '_408': {
    name: '登录记录',
    vue: r => require(['../views/admin/LoginRecord.vue'], r)
  },
  'htfwrz': {
    name: '后台操作日志',
    vue: r => require(['../views/admin/UrlLogAdmin.vue'], r)
  },
  'qdfwrz': {
    name: '前端操作日志',
    vue: r => require(['../views/admin/UrlLogWeb.vue'], r)
  },
  '_20': {
    name: '附件管理',
    vue: r => require(['../views/admin/File.vue'], r)
  },
  'yhdlrztj': {
    name: '用户登录日志统计',
    vue: r => require(['../views/admin/LoginRecordCount.vue'], r)
  }
}