<template>
  <iframe v-if="url" :src="url" :width="width" :height="height" frameborder="0" scrolling="no"></iframe>
</template>

<script>
export default {
  // props: ['dataid',"menuid", "width", "height"],
  props: ["width", "height"],
  data() {
    return {
      url: null
      // mydataId: null
    };
  },
  mounted() {
    // this.setUrl(this.dataid);
    this.setUrl();
  },
  methods: {
    // setUrl(dataid) {
    // console.log("传递的参数", this.dataid, this.menuid);
    // console.log("传递的参数", this.dataid);
    // if (this.mydataId == dataid) return;
    // this.mydataId = dataid;
    // this.url =
    //   "/lxghmap/index.html?dataid=" +
    //   this.mydataId
    //   this.url = "/lxghmap/index.html";
    // }

    setUrl() {
      this.url = "/lxghmap/index.html";
    }
  }
  // watch: {
  //   dataid(newv, oldv) {
  //     this.setUrl(newv);
  //   }
  // }
};
</script>