<template>
  <iframe
    v-if="url"
    :src="url"
    :width="width"
    :height="height"
    frameborder="0"
    scrolling="no"
  ></iframe>
</template>

<script>
export default {
  props: ["sbbm", "width", "height"],
  data() {
    return {
      url: null,
      mysbbm: null,
    };
  },
  mounted() {
    this.setUrl(this.sbbm);
  },
  methods: {
    setUrl(sbbm) {
      if (this.mysbbm == sbbm) return;
      this.mysbbm = sbbm;

      // Am.get("/othservice/hikvision/wsurl?id=" + sbbm, (ret) => {
      Am.get(Am.HIKVISON_SERVER_URL + "?id=" + sbbm, (ret) => {
        //if (ret.indexOf("ws://") == 0) {
          this.url = "/hikvision/h5player.html?url=" + encodeURIComponent(ret);
        //}
      });
    },
  },
  watch: {
    sbbm(newv, oldv) {
      this.setUrl(newv);
    },
  },
};
</script>